import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import AsyncSelect from "react-select/async"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  CreateEntry,
  GetCustomersByName,
  GetData,
} from "helpers/backend_helper"
import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api"
import { useLoading } from "helpers/custom_hooks"
import CustomerModal from "pages/Customers/CustomerModal"
import Select from "react-select"

const NewLocation = props => {
  const setLoading = useLoading()
  const [modal, setModal] = useState(false)
  const [areas, setAreas] = useState([])
  const [reqObj, setReqObj] = useState({ status: "offen" })
  const navigate = useNavigate()

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const handleSave = async _ => {
    setLoading(true)

    const newLocation = await CreateEntry("/locations", reqObj)

    navigate("/standorte/" + newLocation?.id)

    setLoading(false)
  }

  const loadCustomers = async inputValue => {
    const results = await GetCustomersByName(inputValue)

    return results.map(customer => ({
      value: customer.id,
      label:
        customer.company_name ?? customer.last_name + " " + customer.first_name,
    }))
  }

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyCkS0l56SCELQWiWZP78DdjQ2zlb6WTIy4",
  //   libraries,
  // })
  const [searchBox, setSearchBox] = useState(null)

  const onLoad = ref => {
    setSearchBox(ref)
  }

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0] // Annahme: der Benutzer wählt den ersten Vorschlag

    // Objekt für die gespeicherten Adressinformationen
    const addressInfo = {
      street: "",
      streetNumber: "",
      postalCode: "",
      city: "",
    }

    // Zuweisung der Adressinformationen basierend auf den Adresskomponententypen
    place.address_components.forEach(({ types, long_name }) => {
      if (types.includes("route")) addressInfo.street = long_name
      if (types.includes("street_number")) addressInfo.streetNumber = long_name
      if (types.includes("postal_code")) addressInfo.postalCode = long_name
      if (types.includes("locality")) addressInfo.city = long_name
    })

    setReqObj({
      ...reqObj,
      address: addressInfo.street + " " + addressInfo.streetNumber,
      postal_code: addressInfo.postalCode,
      city: addressInfo.city,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/areas")
    setAreas(data?.map(a => ({ label: a.name, value: a.id })))
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Standorte" breadcrumbItem="Neuanlage" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informationen</CardTitle>

                  <Row>
                    <Col sm="6">
                      <div className="mb-3">
                        <Label>Kunde</Label>
                        <div className="d-flex">
                          <AsyncSelect
                            loadOptions={loadCustomers}
                            className="select2-selection w-100"
                            onChange={selectedOption => {
                              setReqObj({
                                ...reqObj,
                                customer_id: selectedOption.value,
                              })
                            }}
                          />
                          <Button
                            type="button"
                            color="primary"
                            className="btn ms-2"
                            onClick={toggle}
                          >
                            Neu
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="mb-3">
                        <Label>Bauvorhaben</Label>
                        <Input
                          type="text"
                          placeholder="Bvh."
                          className="form-control"
                          onChange={e =>
                            setReqObj({ ...reqObj, name: e.target.value })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label>Zustelladresse</Label>
                        <StandaloneSearchBox
                          onLoad={onLoad}
                          onPlacesChanged={onPlacesChanged}
                        >
                          <input
                            type="text"
                            placeholder="Straße"
                            className="form-control"
                          />
                        </StandaloneSearchBox>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label>Bereich</Label>
                        <Select
                          className="select2-selection w-100"
                          onChange={selectedOption => {
                            setReqObj({
                              ...reqObj,
                              area_id: selectedOption.value,
                            })
                          }}
                          options={areas}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label>Zustelldatum</Label>
                        <Input
                          type="date"
                          className="form-control"
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              delivery_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label>Voraussichtliches Abholdatum</Label>
                        <Input
                          type="date"
                          className="form-control"
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              pickup_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="control-label">Status</Label>
                        <select
                          className="form-control select2"
                          onChange={e =>
                            setReqObj({ ...reqObj, status: e.target.value })
                          }
                        >
                          <option disabled>-</option>
                          <option value="offen">offen</option>
                          <option value="bestätigt">bestätigt</option>
                          <option value="abgeschlossen">abgeschlossen</option>
                        </select>
                      </div>
                    </Col>

                    <Col sm="6">
                      <div className="mb-3">
                        <Label>Notiz</Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Notizfeld"
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              note: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      type="submit"
                      color="secondary"
                      className=" "
                      onClick={_ => navigate("/kunden")}
                    >
                      Abbruch
                    </Button>
                    <Button
                      color="success"
                      className="btn"
                      onClick={handleSave}
                    >
                      Speichern
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomerModal
        modal={modal}
        toggle={_ => {
          setModal(false)
          setLoading(false)
        }}
      />
    </React.Fragment>
  )
}

export default NewLocation
