import React, { useEffect, useState } from "react"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
const { SearchBar } = Search
import { Link } from "react-router-dom"
import { Col, Row, Card, CardBody, Table } from "reactstrap"
import { GetData } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import Select from "react-select"
import {
  GetLoggedOnUser,
  IsAdminUser,
  isTimeMidnight,
} from "helpers/misc_helpers"
import UserInfoModal from "components/Common/UserInfoModal"

function Appointments() {
  const setLoading = useLoading()
  const isAdminUser = IsAdminUser()
  const curUser = GetLoggedOnUser()
  const [data, setData] = useState([])
  const [filteredData, setfilteredData] = useState([])
  const [users, setUsers] = useState([])
  const [noUserModal, setNoUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(() => {
    const savedUser = JSON.parse(localStorage.getItem("selectedUser"))

    if (isAdminUser)
      return (
        savedUser || {
          value: curUser?._id,
          label: "alle",
        }
      )

    return {
      value: curUser?._id,
    }
  })

  const [reqObj, setReqObj] = useState({
    date: localStorage.getItem("selectedDate") || new Date(),
  })

  useEffect(() => {
    localStorage.setItem("selectedDate", reqObj.date)
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser))
  }, [reqObj.date, selectedUser])

  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length,
    custom: true,
  }

  const loadData = async () => {
    setLoading(true)
    const userData = await GetData(`/users`)
    setUsers([
      {
        value: -1,
        label: "alle",
      },
      ...userData?.map(a => ({ label: a.name, value: a.id })),
    ])
    const data = await GetData(`/appointments?&date=${reqObj?.date}`)
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [reqObj])

  useEffect(() => {
    filterData()
  }, [selectedUser, data])

  const filterData = () => {
    let result
    if (selectedUser?.value == -1) result = data
    else
      result = data
        .filter(d => d.user_id == selectedUser?.value)
        .sort((a, b) => b.completed - a.completed)
    if (result?.some(r => !r.user_id && r.completed != 1)) setNoUserModal(true)

    if (!isAdminUser) result = result.filter(r => r.completed != 1)
    setfilteredData(result)
  }

  const rowClasses = row => {
    if (row.completed == "1") return "bg-soft bg-success"
    if (!row.user_id) return "bg-soft bg-danger"
    return "bg-soft bg-warning"
  }

  const columns = [
    {
      dataField: "id",
      text: "Name",
      sort: true,
      formatter: (_, element) => (
        <Link to={"/termine/" + element.id}>{element.location_name}</Link>
      ),
    },
    {
      dataField: "address",
      text: "Addresse",
      sort: true,
      formatter: (_, element) => (
        <span>
          {element.address}
          <br />
          {element.postal_code} {element.city}
        </span>
      ),
    },
    {
      dataField: "type",
      text: "Typ",
      sort: true,
    },
    {
      dataField: "date",
      text: "Uhrzeit",
      sort: true,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
      formatter: (_, element) => (
        <>
          {isTimeMidnight(element?.date)
            ? "-"
            : moment(element?.date).format("HH:mm")}
        </>
      ),
    },
    {
      dataField: "area_name",
      text: "Bereich",
      sort: true,
    },
    {
      dataField: "user_name",
      text: "Mitarbeiter",
      sort: true,
      hidden: !isAdminUser,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions || [])}
                    keyField="id"
                    columns={columns || []}
                    data={filteredData || []}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={filteredData}
                        columns={columns || []}
                        remote={{ filter: true }}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <h5 className="mb-0 card-title flex-grow-1">
                                  Termine
                                </h5>
                              </Col>
                              <Col>
                                <div className="d-flex justify-content-end align-items-center">
                                  <SearchBar
                                    placeholder="Suche..."
                                    style={{ width: "250px" }}
                                    {...toolkitProps.searchProps}
                                  />
                                  {isAdminUser && (
                                    <>
                                      <Select
                                        className="select2-selection mb-2 w-25 mx-2"
                                        value={selectedUser}
                                        onChange={selectedOption => {
                                          setSelectedUser(selectedOption)
                                        }}
                                        options={users}
                                      />
                                      <input
                                        type="date"
                                        className="form-control w-25 mb-2"
                                        value={moment(reqObj?.date).format(
                                          "YYYY-MM-DD"
                                        )}
                                        onChange={e =>
                                          setReqObj({
                                            ...reqObj,
                                            date: e.target.value,
                                          })
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                bordered={false}
                                rowClasses={rowClasses}
                                noDataIndication={
                                  "Es sind keine Termine vorhanden!"
                                }
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <UserInfoModal
        onConfirm={_ => setNoUserModal(false)}
        show={noUserModal}
        message={
          "Achtung! Die rot markierten Termine sind ohne zugeordneten Fahrer!"
        }
      />
    </React.Fragment>
  )
}

export default Appointments
