import React, { useEffect, useState } from "react"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
const { SearchBar } = Search
import { Link } from "react-router-dom"
import { Col, Row, Card, CardBody, Table } from "reactstrap"
import { GetData } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"

function Locations() {
  const setLoading = useLoading()
  const [data, setData] = useState([])
  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length,
    custom: true,
  }

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/locations")
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (_, element) => (
        <Link to={"/standorte/" + element.id}>{element.name}</Link>
      ),
    },
    {
      dataField: "customer_name",
      text: "Kunde",
      sort: true,
      formatter: (_, element) => (
        <Link to={"/kunden/" + element.customer_id}>
          {element.customer_name}
        </Link>
      ),
    },
    {
      dataField: "address",
      text: "Adresse",
      sort: true,
    },
    {
      dataField: "postal_code",
      text: "PLZ",
      sort: true,
    },
    {
      dataField: "city",
      text: "Ort",
      sort: true,
    },
    {
      dataField: "delivery_date",
      text: "Lieferdatum",
      sort: true,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
      formatter: (_, element) => (
        <div>
          {element?.delivery_date
            ? moment(element?.delivery_date).format("DD.MM.YYYY")
            : "-"}
        </div>
      ),
    },
    {
      dataField: "pickup_date",
      text: "Abholdatum",
      sort: true,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
      formatter: (_, element) => (
        <div>
          {element?.pickup_date
            ? moment(element?.pickup_date).format("DD.MM.YYYY")
            : "-"}
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      align: () => "end",
      formatter: (_, element) => (
        <div className="d-flex justify-content-end gap-3">
          <Link className="btn btn-primary" to={"/standorte/" + element.id}>
            Details
          </Link>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions || [])}
                    keyField="id"
                    columns={columns || []}
                    data={data || []}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={data}
                        columns={columns || []}
                        remote={{ filter: true }}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <h5 className="mb-0 card-title flex-grow-1">
                                  Standorte
                                </h5>
                              </Col>

                              <Col>
                                <div className="d-flex justify-content-end align-items-center">
                                  <SearchBar
                                    placeholder="Suche..."
                                    style={{ width: "250px" }}
                                    {...toolkitProps.searchProps}
                                  />
                                  <div className="flex-shrink-0 mb-2 ms-3">
                                    <Link
                                      to="/neuer-standort"
                                      className="btn btn-primary me-1"
                                    >
                                      Neuer Standort
                                    </Link>
                                    <Link
                                      to="#!"
                                      className="btn btn-light ms-3"
                                    >
                                      <i className="mdi mdi-refresh"></i>
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                bordered={false}
                                hover={true}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Locations
