import React, { useEffect, useState } from "react"
import deLocale from "@fullcalendar/core/locales/de"
import { Col, Container, Row } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//css
import "@fullcalendar/bootstrap/main.css"
import AreaModal from "./AreaModal"
import { useLoading } from "helpers/custom_hooks"
import { GetData } from "helpers/backend_helper"
import moment from "moment"

const Calender = props => {
  const [events, setEvents] = useState([])
  const [appointments, setAppointments] = useState([])
  const [selectedEntries, setSelectedEntries] = useState([])
  const [modal, setModal] = useState(false)
  const setLoading = useLoading()

  const toggle = () => {
    if (modal) {
      setModal(false)
      setSelectedEntries(null)
      loadData()
    } else {
      setModal(true)
    }
  }

  const handleAppointmentClick = arg => {
    const clickedID = arg?.event?._def?.publicId

    if (clickedID.includes("-")) {
      const [areaId, userId, dateString] = clickedID.split("-")
      // Umwandeln des Datumsstrings in ein Moment-Objekt ohne Zeitzone-Transformation
      const targetDate = moment(dateString, "DD.MM.YYYY").startOf("day")

      const filteredAppointments = appointments.filter(appointment => {
        // Umwandeln des Datums in ein Moment-Objekt ohne Zeitzone-Transformation
        const appointmentDate = moment(appointment.date).startOf("day")

        return (
          appointment.area_id.toString() === areaId &&
          (appointment.user_id?.toString() === userId ||
            (appointment.user_id == null && userId == "null")) &&
          appointmentDate.isSame(targetDate, "day")
        )
      })

      setSelectedEntries(filteredAppointments)
    } else {
      const selectedAppointment = appointments.find(
        appointment => appointment.id.toString() === clickedID
      )
      setSelectedEntries(selectedAppointment ? [selectedAppointment] : [])
    }

    toggle()
  }

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/appointments")

    setAppointments(data)

    groupAndMapAppointments(data)
    setLoading(false)
  }

  const groupAndMapAppointments = data => {
    const grouped = data.reduce((acc, appointment) => {
      if (appointment.area_id) {
        const date = moment(appointment.date).format("DD.MM.YYYY")
        const key = `${appointment.area_id}-${appointment.user_id}-${date}`
        if (!acc[key]) {
          acc[key] = {
            area_id: appointment.area_id,
            area_name: appointment.area_name,
            user_id: appointment.user_id,
            user_name: appointment.user_name,
            date: appointment.date,
            count: 1,
            appointments: [appointment],
          }
        } else {
          acc[key].count += 1
          acc[key].appointments.push(appointment)
        }
      } else {
        // Für Termine ohne area_id einen einzigartigen Key erzeugen
        const uniqueKey = `no-area-${appointment.id}`
        acc[uniqueKey] = {
          ...appointment,
          count: 1,
          appointments: [appointment],
        }
      }
      return acc
    }, {})

    const result = Object.values(grouped).map(group => {
      if (group.count > 1) {
        // Gruppierte Termine
        return {
          id: `${group.area_id}-${group.user_id}-${moment(group.date).format(
            "DD.MM.YYYY"
          )}`,
          title: `${group.area_name};${group.count} Termine;${
            group.user_name ?? "!! Kein USER !!"
          }`,
          start: group.date,
          end: group.date,
          className: group.user_name
            ? "bg-info text-white"
            : "bg-danger text-white",
        }
      } else {
        // Einzeltermine
        const appointment = group.appointments[0]
        return {
          id: appointment.id,
          title: `${appointment.location_name};${appointment.type};${
            appointment.user_name ?? "!! Kein USER !!"
          }`,
          start: appointment.date,
          end: appointment.date,
          className: appointment.user_name
            ? "bg-primary text-white"
            : "bg-danger text-white",
        }
      }
    })

    setEvents(result)
  }

  useEffect(() => {
    loadData()
  }, [])

  const renderEventContent = eventInfo => {
    return (
      <Col>
        {eventInfo.event.title.split(";").map((title, index) => (
          <Row key={index} className="m-0 text-start">
            <span
              className={index === 0 ? "font-weight-bold font-size-16" : ""}
            >
              {title}
            </span>
          </Row>
        ))}
      </Col>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Kalendar" breadcrumbItem="Bereiche" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col lg={12}>
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    slotDuration={"24:00:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    initialView="dayGridWeek"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                    }}
                    events={events}
                    displayEventTime={false}
                    weekends={false}
                    eventClick={handleAppointmentClick}
                    eventContent={renderEventContent}
                    locale={deLocale}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {selectedEntries && (
        <AreaModal
          modal={modal}
          toggle={toggle}
          selectedEntries={selectedEntries}
        />
      )}
    </React.Fragment>
  )
}

export default Calender
