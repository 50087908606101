import { jwtDecode } from "jwt-decode"
import moment from "moment"

export const displayMessage = (error, setError) => {
  setError(error)

  setTimeout(() => {
    setError()
  }, 3000)
}

export const IsAdminUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) {
      const user = jwtDecode(authUser)

      return user.is_admin == 1
    } else return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const GetLoggedOnUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) return jwtDecode(authUser)
  } catch (error) {
    console.log(error)
  }
}

export const isTimeMidnight = date => {
  return moment(date).format("HH:mm") === "00:00"
}
