import React, { useRef, useState } from "react"
import { Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import ReactSignatureCanvas from "react-signature-canvas"
import { CreateEntry, UpdateEntry } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"

const AppointmentModal = ({ modal, toggle, appointment }) => {
  const setLoading = useLoading()
  const signatureRef = useRef()
  const [note, setNote] = useState("")

  const handleSave = async () => {
    setLoading(true)

    const signatureURL = await uploadSignature()
    let continuing = true
    if (!signatureURL)
      continuing = confirm(
        "Keine Unterschrift vorhanden, wollen Sie fortfahren?"
      )

    if (continuing) {
      appointment.completed = 1
      appointment.completed_at = new Date()
      appointment.completion_note = note
      appointment.signature_url = signatureURL

      await UpdateEntry("/appointments/complete", appointment)
    }

    setLoading(false)
    toggle()
  }

  const uploadSignature = async () => {
    try {
      if (signatureRef.current.isEmpty())
        return displayError("Bitte Unterschriften ausfüllen!")

      const formData = new FormData()
      formData.append("file", signatureRef.current.toDataURL())

      const result = await CreateEntry("/uploads/signatures/1", {
        dataUrl: signatureRef.current.toDataURL(),
      })

      return result?.url
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Termin abschliesen
      </ModalHeader>
      <ModalBody>
        <div className="w-100 h-50">
          <Label className="form-label mt-4 ">Notizen: </Label>
          <textarea
            className="form-control"
            onChange={e => setNote(e.target.value)}
          />

          <Label className="form-label mt-4">Unterschrift: </Label>
          <br />
          <ReactSignatureCanvas
            penColor="blue"
            backgroundColor="lightgrey"
            clearButton="true"
            redrawOnResize
            ref={signatureRef}
            canvasProps={{
              width: 450,
              height: 200,
              className: "sigCanvas",
            }}
          />
        </div>
        <button
          className="btn btn-success d-block ms-auto mt-2"
          onClick={handleSave}
        >
          Speichern
        </button>
      </ModalBody>
    </Modal>
  )
}

export default AppointmentModal
