import React, { useEffect, useState } from "react"

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { GetData, UpdateEntry } from "helpers/backend_helper"
import { StandaloneSearchBox } from "@react-google-maps/api"
import { useLoading } from "helpers/custom_hooks"
import Select from "react-select"
import moment from "moment"

const LocationEditModal = ({ modal, toggle, entry }) => {
  const setLoading = useLoading()
  const [areas, setAreas] = useState([])
  const [reqObj, setReqObj] = useState({})

  const [searchBox, setSearchBox] = useState(null)

  const onLoad = ref => {
    setSearchBox(ref)
  }

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0] // Annahme: der Benutzer wählt den ersten Vorschlag

    // Objekt für die gespeicherten Adressinformationen
    const addressInfo = {
      street: "",
      streetNumber: "",
      postalCode: "",
      city: "",
    }

    // Zuweisung der Adressinformationen basierend auf den Adresskomponententypen
    place.address_components.forEach(({ types, long_name }) => {
      if (types.includes("route")) addressInfo.street = long_name
      if (types.includes("street_number")) addressInfo.streetNumber = long_name
      if (types.includes("postal_code")) addressInfo.postalCode = long_name
      if (types.includes("locality")) addressInfo.city = long_name
    })

    setReqObj({
      ...reqObj,
      address: addressInfo.street + " " + addressInfo.streetNumber,
      postal_code: addressInfo.postalCode,
      city: addressInfo.city,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    })
  }

  const handleSave = async () => {
    setLoading(true)
    await UpdateEntry("/locations/" + reqObj.id, reqObj)
    toggle()
  }

  useEffect(() => {
    setReqObj({
      ...entry,
      delivery_date: moment(entry?.delivery_date).format("YYYY-MM-DD"),
      pickup_date: moment(entry?.pickup_date).format("YYYY-MM-DD"),
    })
  }, [entry, modal])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/areas")
    setAreas(data?.map(a => ({ label: a.name, value: a.id })))
    setLoading(false)
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Standort bearbeiten
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label>Bauvorhaben</Label>
                <Input
                  type="text"
                  placeholder="Bvh."
                  className="form-control"
                  value={reqObj?.name ?? ""}
                  onChange={e => setReqObj({ ...reqObj, name: e.target.value })}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label>Zustelladresse</Label>
                <StandaloneSearchBox
                  onLoad={onLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <input
                    type="text"
                    placeholder="Straße"
                    defaultValue={reqObj?.address ?? ""}
                    className="form-control"
                  />
                </StandaloneSearchBox>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label>Bereich</Label>
                <Select
                  className="select2-selection w-100"
                  value={{
                    value: entry?.area_id,
                    label: entry?.area_name,
                  }}
                  onChange={selectedOption => {
                    setReqObj({
                      ...reqObj,
                      area_id: selectedOption.value,
                    })
                  }}
                  options={areas}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label>Zustelldatum</Label>
                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.delivery_date
                      ? moment(reqObj?.delivery_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      delivery_date: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label>Voraussichtliches Abholdatum</Label>
                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.pickup_date
                      ? moment(reqObj?.pickup_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      pickup_date: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="control-label">Status</Label>
                <select
                  className="form-control select2"
                  value={reqObj?.status ?? ""}
                  onChange={e =>
                    setReqObj({ ...reqObj, status: e.target.value })
                  }
                >
                  <option disabled>-</option>
                  <option value="offen">offen</option>
                  <option value="bestätigt">bestätigt</option>
                  <option value="abgeschlossen">abgeschlossen</option>
                </select>
              </div>
            </Col>

            <Col sm="6">
              <div className="mb-3">
                <Label>Notiz</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Notizfeld"
                  value={reqObj?.note ?? ""}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      note: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <Button type="submit" color="secondary" className=" ">
              Abbruch
            </Button>
            <Button type="submit" color="success" className="btn">
              Speichern
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default LocationEditModal
